import React, { useContext } from "react"
import { MenuContext } from "../../context/MenuContext"

const SectionChildren = ({ children }) => {
  const { menuOpen } = useContext(MenuContext)
  return (
    <div
      className="iliad__section--content-toggle"
      style={{ opacity: menuOpen ? "0" : "1" }}
    >
      {children}
    </div>
  )
}

export default SectionChildren
